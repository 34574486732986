import React, { useCallback, useEffect, useState } from "react";

import GlossaryMatches from "../GlossaryMatches/GlossaryMatches";
import { ReactComponent as GlossaryIcon } from "../../assets/glossary.svg";
import { ReactComponent as AddGlossaryWhiteIcon } from "../../assets/addGlossaryWhiteIcon.svg";
import { collection, doc, onSnapshot } from "firebase/firestore";
import { firestore } from "../../firebase";
import { ReactComponent as GlossaryViewIcon } from "../../assets/glossaryViewIcon.svg";
import { ReactComponent as ChevronRight } from "../../assets/chevronRight.svg";
import { ReactComponent as GlossaryStarIcon } from "../../assets/glossaryStarIcon.svg";
import { ReactComponent as BackArrowIcon } from "../../assets/bigBackArrow.svg";
import { toast } from "react-toastify";
import {
  applyGlossaryOnOccurrence,
  applySelectedGlossary,
  clearFindResults,
  getCurrentChapterParagraph,
  getGlossaryItem,
  removeAddedSpanTags,
  scrollOccurrenceContainer,
  updateGlossarytoTheBook,
} from "../../utils/glossaryUtils";
import GlossaryAccordion from "../GlossaryAccordion/GlossaryAccordion";
import { Button, TextField } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import debounce from "lodash.debounce";
import GlossaryWarningModal from "../glosssaryWarningModal/GlossaryWarningModal";
import store from "../../App/store";
import { styled, Tooltip } from "@mui/material";
import GlossarySuggestion from "../GlossarySuggestions/GlossarySuggestion";
import { useDispatch, useSelector } from "react-redux";
import { faCropSimple } from "@fortawesome/free-solid-svg-icons";
import GlossaryScopePopup from "../glossaryScopePopup/GlossaryScopePopup";
import {
  setGlossaryAppliedData,
  setGlossaryList,
  setGlossaryScreenType,
  setIsGlossaryApplying,
} from "../../App/editor/editor.actions";
import { useParams } from "react-router-dom";
import GlossaryAccordionComponent from "../AccordionGlossary/GlossaryAccordionComponent";
import GlossaryFormComponent from "../GlossaryForm/GlossaryFormComponent";
import GlossaryList from "../GlossaryList/GlossaryList";
import {
  addBordersinTranslatedPara,
  scrollToHighlightedText,
  searchTextOverBook,
  updateUserActiveTime,
} from "../../utils/translationUtil";
import { updateTranslatedChapter, useSaveTranslatedChapter } from "../../hooks/useReviewTranslation";
import { cleanGlossaryApplicationData, removeGlossarApplicationDataViaOccurrrence } from "../../utils/firebaseActions";
import PreAppliedGlossaryReview from "../PreAppliedGlossaryReview/PreAppliedGlossaryReview";

function Glossary({
  chapter,
  selectedGlossary,
  setSelectedGlossary,
  applyGlossary,
  setAfterHandledUnsavedChangesAction,
  fetchGlossarySuggestionsData,
  textToAddInGlossary,
  setTextToAddInGlossary,
  sourceEditor,
  handleChapterChange,
  fetchTranslatedChapterPending,
  translateEditor,
  setTranslatedContent,
  setIsLayoutFixed,
  translatedContent,
  setGlossarySectionToggle,
  individualScroll,
}) {
  const successHandler = (data) => null;
  const {
    data,
    mutate: saveEditedTranslatedChapter,
    error,
    isPending,
    isSuccess,
  } = useSaveTranslatedChapter({ successHandler });
  
  const [selectedEnglishWord, setSelectedEnglishWord] = useState("");
  const [openedGlossaryData, setOpenedGlossaryData] = useState("");
  const [loading, setLoading] = useState(false);
  const [glossryLoadingState, setGlossryLoadingState] = useState(false);
  const [glossarySearchInput, setGlossarySearchInput] = useState("");
  const [glossaryFilteredData, setGlossaryFilteredData] = useState([]);
  const { glossarySuggestionData, isGlossaryApplying,glossaryList,glossaryScreenType,isTranslationContentEdited,currentChapter,glossaryAppliedData } =
  useSelector((state) => state.editor);
  const [sectionType, setSectionType] = useState(glossaryScreenType);
  const params = useParams();
 
  const { loggedInUserEmail } = useSelector((state) => state.main);
  const [glossarySuggestionFilteredData, setGlossarySuggestionFilteredData] =
    useState("");
  const [showGlossaryForm, setShowGlossaryForm] = useState(false);
  const [glossaryOperationType, setGlossaryOperationType] = useState("");
  const [glossaryToEdit, setGlossaryToEdit] = useState({});
  const dispatch = useDispatch();
  const [findGlossaryData, setFindGlossaryData] = useState({
    totalOccurances: 0,
    currentIteration: 0,
  });
  const [bookGlossaryData, setBookGlossaryData] = useState(glossaryList);
  const [findOverBookData, setFindOverBookData] = useState(null);
  const [isFirstOccurence, setIsFirstOccurence] = useState(false);

  useEffect(() => {
    let addToGlossarySection;
    if (textToAddInGlossary.text || glossaryScreenType === "glossaryMatches") {
      addToGlossarySection = true;
    }
    if (glossaryScreenType === "glossaryList") {
      addToGlossarySection = false;
      clearFindResults(sourceEditor);
    }
    setSectionType(glossaryScreenType);
  }, [textToAddInGlossary, glossaryScreenType]);

  const getBookGlossaryData = async () => {
    if (chapter?.translation_id) {
      setGlossryLoadingState(true);
      const glossaryItems = await getGlossaryItem(
        chapter?.translation_id,
        chapter?.translation_info?.translated_to?.toLowerCase() || "dutch"
      );
      glossaryItems?.sort((a, b) => {
        if (a.english_word < b.english_word) return -1;
        if (a.english_word > b.english_word) return 1;
        return 0;
      });
      setBookGlossaryData(glossaryItems);
      dispatch(setGlossaryList(glossaryItems ?? []));
      setGlossryLoadingState(false);
    }
  };

  useEffect(() => {
    getBookGlossaryData();
  }, [chapter]);

  const saveTranslatedChapter = async () => {
    try {
      const isTranslatedContentEdited =
      store?.getState()?.editor?.isTranslationContentEdited;
      if(!isTranslatedContentEdited) return
      let currentChapter = translateEditor?.getData();
      const editorStore = translateEditor.config.get("store").getState();
      setIsLayoutFixed(false);
      await updateTranslatedChapter(
        currentChapter,
        editorStore.editor.currentChapter,
        params.id
      );
    } catch (error) {
      console.log("**ERROR IN SAVING TRANSLATED CHAPTER***", error.message);
    }
  };


  const handleApplyGlossary = async (operationType) => {
    updateUserActiveTime(params.id,loggedInUserEmail)
    if (store.getState().editor.isGlossaryApplying.status) {
      toast(`Please wait for the previous glossary process to complete.`, {
        type: "warning",
        autoClose: 3000,
      });
      return;
    }

    if (!openedGlossaryData?.english_word) return;

    let selectedGlossaryItem = bookGlossaryData.find(
      (glossary) => glossary?.english_word === openedGlossaryData?.english_word
    );
    if (!selectedGlossaryItem) return;
    setSelectedGlossary({ englishWord: "", variant: "" });
    
    // if (isTranslatedContentEdited) {
      // setAfterHandledUnsavedChangesAction({
      //   actionName: "applyGlossary",
      //   actionData: {
      //     applicationType: "glossary",
      //     selectedGlossaryItem,
      //     chapterData: chapter,
      //     operationType: operationType,
      //   },
      // });
      // return;
    // }
    await saveTranslatedChapter()
    setLoading(true);
    await applyGlossary({
      applicationType: "glossary",
      chapterData: chapter,
      selectedGlossaryItem,
      operationType,
    });
    setLoading(false);
  };



  useEffect(() => {
    setGlossaryFilteredData(bookGlossaryData);
    setGlossarySuggestionFilteredData(glossarySuggestionData);
    setGlossarySearchInput("");
  }, [bookGlossaryData, glossarySuggestionData, sectionType]);

  const debouncedGlossarySearch = useCallback(
    debounce((searchInput) => {
      if (searchInput) {
        const lowercasedSearchTerm = searchInput.trim().toLowerCase();
        updateUserActiveTime(params.id,loggedInUserEmail)
         
        if (sectionType === "glossaryList") {
          const newFilteredData = bookGlossaryData.filter((glossary) => {
            if (
              glossary.english_word.toLowerCase().includes(lowercasedSearchTerm)
            )
              return true;

            let isVariantFound = false;
            glossary.dutch_word_array.forEach((variant) => {
              if (
                variant.replacement_dutch_word
                  .toLowerCase()
                  .includes(lowercasedSearchTerm)
              )
                isVariantFound = true;
            });

            return isVariantFound;
          });
          setGlossaryFilteredData(newFilteredData);
        }

        if (sectionType === "suggestions") {
          const newFilteredData = glossarySuggestionData.filter((glossary) => {
            if (
              glossary.english_word.toLowerCase().includes(lowercasedSearchTerm)
            )
              return true;
            if (
              glossary.dutch_word_array[
                glossary.applied_index
              ].replacement_dutch_word
                .toLowerCase()
                .includes(lowercasedSearchTerm)
            )
              return true;
          });
          setGlossarySuggestionFilteredData(newFilteredData);
        }
      } else {
        sectionType === "glossaryList" &&
          setGlossaryFilteredData(bookGlossaryData);
        sectionType === "suggestions" &&
          setGlossarySuggestionFilteredData(glossarySuggestionData);
      }
    }, 500),
    [bookGlossaryData, glossarySuggestionFilteredData, sectionType]
  );

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setGlossarySearchInput(value);
    debouncedGlossarySearch(value);
  };


  useEffect(() => {
    if (chapter?.chapter_data) {
      const parser = new DOMParser();
      const englishSentenceElem = parser.parseFromString(
        chapter.chapter_data.English,
        "text/html"
      );
      //  console.log("englishChapter",englishSentenceElem?.body?.textContent)
    }

    return ()=>handleCloseGlossaryPannel()
  }, []);

  useEffect(() => {
    const updateGlossaryAppliedData = async () => {
      if (fetchTranslatedChapterPending) return;
      if (isGlossaryApplying.status) {
        translateEditor.enableReadOnlyMode(translateEditor.id);
      } else {
        translateEditor.disableReadOnlyMode(translateEditor.id);
      }

      const docRef = doc(firestore, "live_glossary_application", params.id);
      const unsubscribe = onSnapshot(docRef, (doc) => {
        let newData = {};
        if (doc.exists()) newData = { id: doc.id, ...doc.data() };

        const currentBookData = newData ?? {};
        if (!currentBookData?.english_word) {
          dispatch(setGlossaryAppliedData({}));
          document.documentElement.style.removeProperty(
            `--glossary-changes-bg`,
            "#3B37FF26"
          );
          return;
        }
        let formatedData = {};
        // if (isGlossaryApplying.status) {

        formatedData[currentBookData?.english_word] = {
          glossaryItemDetails: {
            english_word: currentBookData.english_word,
            glossary_item_id: currentBookData.glossary_item_id,
          },
          affectedChaptersData: currentBookData.updated_resources,
          status: "Applying",
        };

        dispatch(setGlossaryAppliedData(formatedData));
        document.documentElement.style.setProperty(
          `--glossary-changes-bg`,
          "#3B37FF26"
        );
        const applyingGlossaryData =
          formatedData?.[
            isGlossaryApplying.englishWord || Object?.keys(formatedData)?.[0]
          ]?.affectedChaptersData || {};

        let currentChapter = translateEditor?.getData();
        let isChapterEdited=false
        let currentChapterPara = "";
        Object.keys(applyingGlossaryData)?.forEach((occuranceNo) => {
          let changedParagraphData = applyingGlossaryData[occuranceNo];
          if (
            !changedParagraphData?.final_translated_paragraph_with_tags?.trim()
            || !changedParagraphData?.paragraph_before_glossary?.trim()
            || !changedParagraphData?.paragraph_after_glossary?.trim()
          )
            return;
          currentChapterPara = getCurrentChapterParagraph(
            currentChapter,
            changedParagraphData?.paragraph_before_glossary,
            changedParagraphData?.paragraph_after_glossary
          );
          let currentChapterNo = store.getState().editor.currentChapter;
          if (
            currentChapter &&
            currentChapterPara &&
            Number(changedParagraphData.chapter_number) ===
              Number(currentChapterNo)
          ) {
            console.log(
              "matched check old para",
              currentChapterPara
            );
            console.log(
              "matched check latest para",
              occuranceNo,
              "----",
              changedParagraphData?.final_translated_paragraph_with_tags
            );
            currentChapter = currentChapter?.replace(
              currentChapterPara,
              changedParagraphData?.final_translated_paragraph_with_tags
            );
            isChapterEdited=true
          }
        });
        if (currentChapter && isChapterEdited) {
          setTimeout(() => {
            setTranslatedContent(currentChapter + " ");
            setIsLayoutFixed(false);
            // setTimeout(()=>{
            //   addBordersinTranslatedPara()
            // },100)
          }, 100);
        }
        // }
      });
      return () => unsubscribe();
    };
    updateGlossaryAppliedData();
  }, [isGlossaryApplying, fetchTranslatedChapterPending]);

  const handleChangeSection = ({
    type,
    selectedEnglishWord,
    operationType,
    glossaryToEdit,
  }) => {
    updateUserActiveTime(params.id,loggedInUserEmail)
    switch (type) {
      case "addGlossary":
        setGlossaryOperationType(operationType || "");
        setShowGlossaryForm(true);
        setSelectedEnglishWord(selectedEnglishWord || "");
        setGlossaryToEdit(glossaryToEdit || {});
        setSectionType(type);
      case "glossaryList":
        setSectionType(type);
        setGlossaryOperationType(operationType || "");
        setShowGlossaryForm(false);
        setSelectedEnglishWord(selectedEnglishWord || "");
        setGlossaryToEdit(glossaryToEdit || {});
    }
  };

  const updateFindTextResults = async (englishWord) => {
    updateUserActiveTime(params.id,loggedInUserEmail)
    const findAndReplaceEditing = sourceEditor.plugins.get(
      "FindAndReplaceEditing"
    );

    let totalCountsOverBook = 0;
    let OverBookIterationNo = 0;
    let wordOccurrencesOverBook;
    if (!findOverBookData || findOverBookData?.englishWord !== englishWord) {
      const data =
        (await searchTextOverBook(
          params.id,
          englishWord,
          "false",
          "true",
          "english"
        )) ?? {};

      wordOccurrencesOverBook = data.word_occurrences;
      setFindOverBookData({
        englishWord: englishWord,
        wordOccurrencesOverBook,
        chapterWiseOccurancePara: data?.chapter_paragraph_occurrences,
      });
    } else {
      wordOccurrencesOverBook = findOverBookData?.wordOccurrencesOverBook;
    }

    for (const key in wordOccurrencesOverBook) {
      if (Number(key) !== chapter?.chapter_number)
        totalCountsOverBook += wordOccurrencesOverBook[key];

      if (Number(key) < chapter?.chapter_number) {
        OverBookIterationNo += wordOccurrencesOverBook[key];
      }
    }

    let currentChapterOccurances =
      findAndReplaceEditing?.state?.results?.length ?? 0;
    let currentChapterIteration =
      findAndReplaceEditing?.state?.highlightedOffset ?? 0;

    setFindGlossaryData({
      englishWord: englishWord,
      totalOccurances: totalCountsOverBook + currentChapterOccurances,
      currentIteration: currentChapterIteration + OverBookIterationNo,
    });
    scrollToHighlightedText("source", individualScroll);
    scrollOccurrenceContainer('selectedOccurrence');
  };

  const isChaptersLastOccurances = (type) => {
    if (!findOverBookData?.wordOccurrencesOverBook) return;
    const findAndReplaceEditing = sourceEditor.plugins.get(
      "FindAndReplaceEditing"
    );
    let currentChapterOccurances =
      findAndReplaceEditing?.state?.results?.length ?? 0;
    let currentChapterIteration =
      findAndReplaceEditing?.state?.highlightedOffset ?? 0;

    const chapterArray = Object.keys(
      findOverBookData?.wordOccurrencesOverBook
    )?.sort((a, b) => Number(a) - Number(b));
    let currentChapterNo = store.getState().editor.currentChapter;
    let currentChapterIndex = chapterArray.findIndex(
      (chapterNo) => Number(chapterNo) === currentChapterNo
    );

    if(chapterArray.length===1)
      return false

    if (
      type === "next" &&
      currentChapterOccurances === currentChapterIteration
    ) {
      let nextChapter =
        currentChapterIndex + 1 >= chapterArray.length
          ? Number(chapterArray[0])
          : Number(chapterArray[currentChapterIndex + 1]);
      handleChapterChange(nextChapter);
      return true;
    }

    if (type === "prev" && currentChapterIteration === 1) {
      let prevChap =
        currentChapterIndex - 1 < 0
          ? Number(chapterArray[chapterArray.length - 1])
          : Number(chapterArray[currentChapterIndex - 1]);
      handleChapterChange(prevChap);
      setTimeout(() => {
        setIsFirstOccurence(true);
      });
      return true;
    }

    return false;
  };

  const findWordInSourceEditor = ({
    operation,
    englishText,
    isFirstOccurence,
    occurrenceNo,
  }) => {
    if (fetchTranslatedChapterPending) return;
    switch (operation) {
      case "find":
        const findAndReplaceEditing = sourceEditor.plugins.get(
          "FindAndReplaceEditing"
        );
        findAndReplaceEditing?.state?.set("matchWholeWords", true);

        if (isFirstOccurence) {
          setTimeout(() => {
            findAndReplaceEditing?.state?.set(
              "highlightedResult",
              findAndReplaceEditing?.state?.results?.get(
                findAndReplaceEditing?.state?.results?.length - 1 ?? 0
              )
            );
            updateFindTextResults(englishText);
          }, 10);
          setIsFirstOccurence(false);
          return;
        }

        if (occurrenceNo) {
          setTimeout(() => {
            findAndReplaceEditing?.state?.set(
              "highlightedResult",
              occurrenceNo
            );
            updateFindTextResults(findGlossaryData.englishWord);
          }, 10);
          return;
        }
        findAndReplaceEditing.find(englishText, {
          searchText: englishText,
          matchCase: false,
          wholeWords: true,
        });
        updateFindTextResults(englishText);
        break;
      case "prev":
        if (!isChaptersLastOccurances(operation)) {
          sourceEditor.execute("findPrevious");
          updateFindTextResults(findGlossaryData.englishWord);
        }
        break;
      case "next":
        if (!isChaptersLastOccurances(operation)) {
          sourceEditor.execute("findNext");
          updateFindTextResults(findGlossaryData.englishWord);
        }
        break;
    }
  };

  const resetAccordianValues = () => {
    setOpenedGlossaryData("");
    clearFindResults(sourceEditor);
    setFindGlossaryData({
      totalOccurances: 0,
      currentIteration: 0,
    });
    setFindOverBookData({});
  };


  const removeGlossaryChangesFromTranslatedChapter= async()=>{
    try {
      // if(isTranslationContentEdited) return ;
        const updatedChapter=removeAddedSpanTags(translateEditor)
        if(!updatedChapter?.trim()) return ;
        setTranslatedContent(updatedChapter)
        const store = translateEditor.config.get("store").getState();
        setIsLayoutFixed(false)
        saveEditedTranslatedChapter({
          translation_id: params.id,
          chapter: store.editor.currentChapter,
          translated_data: updatedChapter,
        });
    } catch (error) {
      console.log("error in saving new chapter",error.message)
    }
  }


  const handleCloseGlossaryPannel =  async (screenType) => {
    if (isGlossaryApplying.status) return;
    updateUserActiveTime(params.id,loggedInUserEmail)
   
    clearFindResults(sourceEditor)
    if(sectionType!=="preAppliedGlossaryReview" && Object.keys(glossaryAppliedData).length){
      removeGlossaryChangesFromTranslatedChapter()
    }
    dispatch(setGlossaryScreenType(""))
    document.documentElement.style.removeProperty(
      `--glossary-highlight-bg`,
      "#3B37FF33"
    );
    setFindOverBookData(null);
    document.documentElement.style.removeProperty(
      `--glossary-highlight-transparent-bg`,
      "transparent"
    );

    document.documentElement.style.removeProperty(
      `--glossary-changes-bg`,
      "#3B37FF26"
    );

    cleanGlossaryApplicationData(params.id)
    dispatch(setIsGlossaryApplying({status:false,englishWord:"",operationType:"",occurrenceNo:""}))
    setFindOverBookData({
      englishWord: "",
      wordOccurrencesOverBook: "",
      chapterWiseOccurancePara: "",
    });
    setFindGlossaryData({
      englishWord: "",
      totalOccurances: "",
      currentIteration: "",
    });

    if(screenType==="glossaryList"){
      const glossaryButton = document.querySelector(
        '[data-cke-tooltip-text="Glossary"]'
      );
      glossaryButton?.click();
    }else{

      setGlossarySectionToggle(false)
    }
  };
   


  const getWordOccurrenceNo=(currentChapterOccurenceNo,wordOccurrencesData,chapterNo)=>{
    let occurrenceNo=0
    Object.keys(wordOccurrencesData).forEach((chapterKey)=>{
      let totalOccurances=wordOccurrencesData[chapterKey]
      if(Number(chapterKey)<chapterNo)
        occurrenceNo+=totalOccurances
      
      if(Number(chapterKey)===chapterNo)
        occurrenceNo+=currentChapterOccurenceNo
    })

    return occurrenceNo
  }
  
  
  const handleFixGlossary=async(changeSection)=>{
    updateUserActiveTime(params.id,loggedInUserEmail)
    const findAndReplaceEditing = sourceEditor.plugins.get(
      "FindAndReplaceEditing"
    );
    const currentChapterOccurenceNo=findAndReplaceEditing.state.highlightedOffset;
    const wordOccurrenceNo=getWordOccurrenceNo(currentChapterOccurenceNo,findOverBookData.wordOccurrencesOverBook,currentChapter)
    const paragraphs = document.querySelectorAll('#source .ck-editor__editable > p');
    let englishParagraph=""   
    paragraphs.forEach((paragraph)=>{
         const spanElement=paragraph.querySelector(".ck-find-result_selected")
         if(spanElement){
          englishParagraph=paragraph.outerHTML
         }
    })
     
    if(!englishParagraph || !wordOccurrenceNo || !openedGlossaryData) return
    await saveTranslatedChapter();
    let updatedGlossaryAppliedData=glossaryAppliedData    
    removeGlossarApplicationDataViaOccurrrence(wordOccurrenceNo,params.id)
    dispatch(setIsGlossaryApplying({status:true,englishWord:findOverBookData.englishWord,operationType:"fix",occurrenceNo:wordOccurrenceNo}))
    // setSectionType("applicationStatus");
    changeSection()
    const payload={
      unique_string:params.id,
      glossary_id:openedGlossaryData.id,
      chapter_id:`chapter_${currentChapter}`,
      original_english_paragraph_with_html:englishParagraph,
      variant_index:0,
      applied_by:loggedInUserEmail,
      application_source:"glossary",
      variant_selection_type:"full_context_type",
      word_occurrence_number:wordOccurrenceNo
    }
  
    await applyGlossaryOnOccurrence(payload)
    if (!isChaptersLastOccurances("next") && findGlossaryData?.currentIteration !==
    findGlossaryData?.totalOccurances) {
      sourceEditor.execute("findNext");
      updateFindTextResults(findGlossaryData.englishWord);
    }
    dispatch(setIsGlossaryApplying({status:false,englishWord:findOverBookData.englishWord,operationType:'fix',occurrenceNo:wordOccurrenceNo}))    
  }



  return (
    <div
      style={{ height: "calc(100dvh - 133px - 71px)" }}
      className="w-[576px] shrink-0 mt-[150px] rounded-md  relative ml-4 "
    >
      {sectionType === "glossaryMatches" ? (
        <GlossaryMatches
          glossarySearchInput={glossarySearchInput}
          highlightedEnglish={textToAddInGlossary}
          chapter={chapter}
          glossaryData={bookGlossaryData}
          selectedEnglishWord={selectedEnglishWord}
          setGlossarySearchInput={setGlossarySearchInput}
          getBookGlossaryData={getBookGlossaryData}
          handleApplyGlossary={handleApplyGlossary}
          setSelectedGlossary={setSelectedGlossary}
          selectedGlossary={selectedGlossary}
          setSelectedEnglishWord={setSelectedEnglishWord}
          sourceEditor={sourceEditor}
          handleChapterChange={handleChapterChange}
          fetchTranslatedChapterPending={fetchTranslatedChapterPending}
          setOpenedGlossaryData={setOpenedGlossaryData}
          openedGlossaryData={openedGlossaryData}
          translateEditor={translateEditor}
          setIsLayoutFixed={setIsLayoutFixed}
          setTranslatedContent={setTranslatedContent}
          setGlossarySectionToggle={setGlossarySectionToggle}
          individualScroll={individualScroll}
          updateFindTextResults={updateFindTextResults}
          findOverBookData={findOverBookData}
          setFindOverBookData={setFindOverBookData}
          setFindGlossaryData={setFindGlossaryData}
          findGlossaryData={findGlossaryData}
          findWordInSourceEditor={findWordInSourceEditor}
          isChaptersLastOccurances={isChaptersLastOccurances}
          isFirstOccurence={isFirstOccurence}
          setIsFirstOccurence={setIsFirstOccurence}
          removeGlossaryChangesFromTranslatedChapter={
            removeGlossaryChangesFromTranslatedChapter
          }
          handleCloseGlossaryPannel={handleCloseGlossaryPannel}
          handleFixGlossary={handleFixGlossary}
        />
      ) : sectionType === "addGlossary" ? (
        <GlossaryFormComponent
          chapter={chapter}
          operationType={glossaryOperationType}
          setBookGlossaryData={setBookGlossaryData}
          bookGlossaryData={bookGlossaryData}
          setShowGlossaryForm={setShowGlossaryForm}
          englishWord={selectedEnglishWord}
          glossaryToEdit={glossaryToEdit}
          // setGlossaryToEdit={setGlossaryToEdit}
          // setSelectedEnglishWord={setSelectedEnglishWord}
          // setGlossaryOperationType={setGlossaryOperationType}
          handleCloseGlossary={handleCloseGlossaryPannel}
          handleChangeSection={handleChangeSection}
        />
      ) : sectionType === "glossaryList" ? (
        <GlossaryList
          handleChangeSection={handleChangeSection}
          handleCloseGlossary={handleCloseGlossaryPannel}
          setOpenedGlossaryData={setOpenedGlossaryData}
          openedGlossaryData={openedGlossaryData}
          chapter={chapter}
          glossaryFilteredData={glossaryFilteredData}
          glossaryLoadingState={glossryLoadingState}
          glossarySearchInput={glossarySearchInput}
          handleSearchChange={handleSearchChange}
          bookGlossaryData={bookGlossaryData}
          setBookGlossaryData={setBookGlossaryData}
          findWordInSourceEditor={findWordInSourceEditor}
          sourceEditor={sourceEditor}
          findGlossaryData={findGlossaryData}
          findOverBookData={findOverBookData}
          resetAccordianValues={resetAccordianValues}
          isFirstOccurence={isFirstOccurence}
          setIsFirstOccurence={setIsFirstOccurence}
          translateEditor={translateEditor}
          fetchTranslatedChapterPending={fetchTranslatedChapterPending}
          handleChapterChange={handleChapterChange}
          updateFindTextResults={updateFindTextResults}
          removeGlossaryChangesFromTranslatedChapter={
            removeGlossaryChangesFromTranslatedChapter
          }
          handleApplyGlossary={handleApplyGlossary}
          handleFixGlossary={handleFixGlossary}
        />
      ) : (
        <PreAppliedGlossaryReview
          chapter={chapter}
          handleCloseGlossaryPannel={handleCloseGlossaryPannel}
          selectedCardId={textToAddInGlossary.text}
          translateEditor={translateEditor}
          handleChapterChange={handleChapterChange}
          fetchTranslatedChapterPending={fetchTranslatedChapterPending}
          setTranslatedContent={setTranslatedContent}
          setIsLayoutFixed={setIsLayoutFixed}
        />
      )}

      {/* {glossarySearchInput &&
                glossarySuggestionFilteredData.length ? (
                <div className="w-full justify-center items-center flex-col flex pt-4 gap-2">
                  <p className="text-[#5F6368] text-  [10px] m-0">Opps!</p>

                  <p className="text-[#CF1322] text-[14px] m-0">
                    Searched glossary suggestion does not exist
                  </p>
                </div>
              ) : (
                <GlossarySuggestion
                  applyGlossary={applyGlossary}
                  glossarySuggestionData={glossarySuggestionFilteredData}
                  chapter={chapter}
                  fetchGlossarySuggestionsData={fetchGlossarySuggestionsData}
                  setAfterHandledUnsavedChangesAction={
                    setAfterHandledUnsavedChangesAction
                  }
                />
        )} */}
    </div>
  );
}

export default Glossary;
