import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { ReactComponent as GlossaryViewIcon } from "../../assets/glossaryViewIcon.svg";
function ViewReasonAccordian({latestDutchSentence,defaultOpen,applicationReason,variantUsed}) {
    const [expanded, setExpanded] = useState(defaultOpen?true:false);
    const [data, setData] = useState({reason:"",variantUsed:""})
    
    const extractReasonFromSentence = () => {
      if(!latestDutchSentence) return ;
      const parser = new DOMParser();
      const parsedSentenceElement = parser?.parseFromString(
        `<div>${latestDutchSentence}</div>`,
        "text/html"
      );
      const sentenceElement = parsedSentenceElement?.querySelector(
        "[data-replacement-reason]"
      );
      const skippedElement = parsedSentenceElement?.querySelector(
        ".skipped[data-replacement-reason]"
      );
      const addedElement = parsedSentenceElement?.querySelector(
        ".added[data-replacement-reason]"
      );
      let variantUsed = addedElement?.getAttribute("variant_used")
        ? addedElement?.getAttribute("variant_used")
        : skippedElement?.getAttribute("variant_used");
      setData({
        reason: sentenceElement?.getAttribute("data-replacement-reason"),
        variantUsed: variantUsed,
      });
    };

    useEffect(() => {
        extractReasonFromSentence()
    }, [latestDutchSentence])
  
    useEffect(() => {
      setExpanded(defaultOpen)
    }, [defaultOpen])
    
    
    const handleAccordianChange=()=>{
        setExpanded(!expanded)
    }


  return (
    <Accordion
    //   key={}
      expanded={expanded}
      onChange={handleAccordianChange}
      sx={{
        borderBottomLeftRadius: "0",
        border:"0.5px dashed rgba(30, 28, 128, 0.60)",
        background:"rgba(255, 255, 255, 0.70)",
        "&:last-of-type": {
          borderRadius: "4px",
        },
        "&::before": {
          height: 0, // Removes the height
        },
        boxShadow: "none",
        // borderBottom: "1px solid #BFBFBF",
        "& .MuiAccordion-region": { height: "100%" },
        "& .MuiAccordionSummary-root": {
          // Targeting the accordion summary
          "&.Mui-expanded": {
            // Adjust min-height when expanded
            minHeight: "auto",
          },
        },

        "& .MuiPaper-root": {
          // Targeting the accordion summary
          "&.Mui-expanded": {
            // Adjust min-height when expanded
            margin: "0px",
          },
        },
      }}
    >
      <AccordionSummary
        expandIcon={<GlossaryViewIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
        sx={{
          display: "flex",
          borderBottomLeftRadius: "0",
          paddingBottom: "0",
          minHeight: "30px",
          height: "35px",
          margin:'0px', 
        }}
      >
       <div className="text-xs font-[500] text-[#393939]">
        Why was {" "}
        {variantUsed || data?.variantUsed ? <span className="bg-[#3EAF3F26] text-[#3EAF3F] ">
        "{variantUsed || data?.variantUsed}"
        </span>:"this"}
        {" "}
         used?
        </div> 
      </AccordionSummary>
      <AccordionDetails
        sx={{
          padding: "0",
          border: "none",
          boxShadow: "none",
          borderBottomLeftRadius: "0",
        //   height: "100%",
        }}
      ></AccordionDetails>
      <div className="px-4 pb-3 text-xs italic text-[#3E3E3E]">
      {applicationReason || data?.reason}
      </div>
    </Accordion>
  );
}

export default ViewReasonAccordian;
